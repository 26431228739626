<template>
  <div class="home">
    <md-toolbar class="md-primary toolbar md-dense">
      <h3 class="md-title">DeepinkDev</h3>
      <div class="space"></div>
      <md-button class="toolbar-button" @click="runCode">
        <div class="content">
          <md-icon>play_for_work</md-icon>
          <div class="button-text">运行</div>
        </div>
      </md-button>
      <md-button class="toolbar-button" @click="reset">
        <div class="content">
          <md-icon>clear_all</md-icon>
          <div class="button-text">重置</div>
        </div>
      </md-button>
      <div>
        <md-dialog-prompt
          :md-active.sync="active"
          @md-confirm="confirmAddr"
          v-model="addr"
          md-title="客户端地址"
          md-input-placeholder="如 192.168.1.123:8080"
          md-confirm-text="确定"
          md-cancel-text="取消"
        />
        <md-button class="toolbar-button" @click="active = true">
          <div class="content">
            <md-icon>settings_ethernet</md-icon>
            <div class="button-text">{{ addr ? addr : '设置' }}</div>
          </div>
        </md-button>
      </div>
      <md-menu md-direction="bottom-start" md-align-trigger class="more-button">
        <md-button md-menu-trigger class="md-icon-button">
          <md-icon>more_vert</md-icon>
        </md-button>
        <md-menu-content>
          <md-menu-item @click="navTo('https://jsoup.org/cookbook/extracting-data/selector-syntax')">
            Jsoup 教程
          </md-menu-item>
          <md-menu-item @click="navTo('https://developer.mozilla.org/zh-CN/docs/Web/JavaScript')">
            JavaScript 教程
          </md-menu-item>
          <md-menu-item @click="navTo('https://github.com/deepink-app/booksource')">在线书源仓库</md-menu-item>
        </md-menu-content>
      </md-menu>
    </md-toolbar>
    <div class="editor">
      <editor ref="editor" language="javascript" />
    </div>
  </div>
</template>

<script>
import Editor from '@/components/Editor.vue'
export default {
  components: {
    Editor,
  },
  data() {
    return {
      key: 'value',
      active: false,
      addr: null,
      code: 'javascript',
      cmOptions: {},
    }
  },
  created() {
    this.addr = localStorage.getItem('addr')
  },
  methods: {
    confirmAddr(val) {
      this.addr = val
      localStorage.setItem('addr', val)
    },
    runCode() {
      this.$refs.editor.handleSave()
    },
    reset() {
      this.$refs.editor.handleReset()
    },
    navTo(url) {
      window.open(url, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .toolbar {
    position: relative;
    .space {
      width: 36px;
    }
    .toolbar-button {
      .content {
        display: flex;
        display: flex;
        align-items: center;
        justify-content: center;
        .button-text {
          padding: 0 4px;
        }
      }
    }
    .more-button {
      position: absolute;
      right: 16px;
      top: 4px;
    }
  }

  .editor {
    width: 100%;
    height: calc(100% - 50px);
  }
}
</style>
