import Vue from 'vue'
// import {
//   MdButton,
//   MdContent,
//   MdTabs,
//   MdToolbar,
//   MdIcon,
//   MdDialog,
//   MdDialogPrompt,
//   MdField,
//   MdMenu,
//   MdMenuItem,
// } from 'vue-material/dist/components'
// import 'vue-material/dist/vue-material.min.css'
// import 'vue-material/dist/theme/default.css'

// Vue.use(MdButton)
// Vue.use(MdContent)
// Vue.use(MdTabs)
// Vue.use(MdToolbar)
// Vue.use(MdIcon)
// Vue.use(MdDialog)
// Vue.use(MdDialogPrompt)
// Vue.use(MdField)
// Vue.use(MdMenu)
// Vue.use(MdMenuItem)
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
Vue.use(VueMaterial)
