<template>
  <div class="monaco-editor" ref="editor" :style="style"></div>
</template>

<script>
//全部引入
// import * as monaco from 'monaco-editor'
import axios from 'axios'
import exampleStr from './example'
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api'
import 'monaco-editor/esm/vs/basic-languages/javascript/javascript.contribution'
import 'monaco-editor/esm/vs/editor/contrib/find/findController.js'
export default {
  name: 'monaco-editor',
  //引入组件
  props: {
    width: {
      type: [String, Number],
      default: '100%',
    },
    height: {
      type: [String, Number],
      default: '100%',
    },
    code: {
      type: String,
      default: '',
    },
    theme: {
      type: String,
      default: 'vs',
    },
    language: {
      type: String,
      default: 'javascript',
    },
    options: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    //这里存放数据
    return {
      editor: null, //文本编辑器
      codeOptions: {
        value: this.code, //编辑器初始显示文字
        language: this.language, //语言支持自行查阅 demo
        readOnly: false, //只读
        scrollBeyondLastLine: false, //底部代码不留白
        theme: this.theme, //官方自带三种主题 vs, hc-black, or vs-dark
        roundedSelection: true,
        ...this.option,
      },
    }
  },
  //监听属性 类似于 data 概念
  computed: {
    style() {
      const { width, height } = this
      const fixedWidth = /(%|px)/.test(width) ? width : width + 'px'
      const fixedHeight = /(%|px)/.test(height) ? height : height + 'px'
      return {
        width: fixedWidth,
        height: fixedHeight,
      }
    },
  },
  //监控 data 中的数据变化
  watch: {
    code: {
      handler(newName) {
        this.setValue(newName)
      },
    },
  },
  //方法集合
  methods: {
    initEditor() {
      // 初始化编辑器，确保 dom 已经渲染
      this.editor = monaco.editor.create(this.$refs.editor, this.codeOptions)
      this.editor.getModel().updateOptions({ tabSize: 2 })
      let code = localStorage.getItem('code')
      this.editor.setValue(code ? code : exampleStr)
      this.editor.onDidChangeModelContent(e => {
        let code = this.editor.getValue()
        localStorage.setItem('code', code)
      })
    },
    getValue() {
      this.editor.getValue() //获取编辑器中的文本
    },
    setValue(val) {
      this.editor.setValue(val) //设置编辑器中的文本
    },
    handleSave() {
      this.editor.getAction('editor.action.formatDocument').run()
      let val = this.editor.getValue()
      let url = localStorage.getItem('addr')
      axios.post(`http://${url}/dev/update`, val, {
        headers: {
          'Content-Type': 'text/plain',
        },
      })
      return false
    },
    handleReset() {
      this.editor.setValue(exampleStr)
    },
  },
  //生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted() {
    this.initEditor()
  },
  beforeDestroy() {},
  //生命周期 - 销毁完成
  destroyed() {
    this.editor.dispose()
  },
}
</script>
